import {React} from 'react';
import { NavLink} from "react-router-dom";
import { useCookies } from "react-cookie";
import '../App.css';



function CookieConsent(){
    
    const [cookies,setCookie] = useCookies(['cookieConsent'])
   
    
    const giveCookieConsent = ()=>{
        setCookie('cookieConsent',true,{path:"/"})

    }
    return(
        <>
            
            <div className='cookies'>
                <div className='blackcookiebanner'></div>
                <div className='cookiebanner'>
                    <h3>Diese Website verwendet Cookies</h3>

                    <p> 
                    Diese Website verwendet ausschließlich technische Cookies, um die Funktionalität der Website sicherzustellen und Ihre Nutzererfahrung zu verbessern. Durch die Nutzung dieser Website stimmen Sie der Verwendung von Cookies zu. Sie können Ihre Cookie-Einstellungen jederzeit ändern. Weitere Informationen finden Sie in unserer Datenschutzerklärung.
                    </p>

                    <div className="actions">
                    
                    <button onClick={giveCookieConsent}><NavLink to={"/Datenschutz"}>Datenschutz</NavLink></button>
                    <button onClick={giveCookieConsent}><NavLink to={"/"}>Akzeptieren und weiter</NavLink></button>
                    
                    </div>
                </div>

            </div>
        </>
    )

}

export default CookieConsent;