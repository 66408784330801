import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { useCookies } from "react-cookie";
import Navbar from "./components/Navbar"
import Home from "./containers/Home"
import Tech from "./containers/Tech"
import Impressum from "./containers/Impressum"
import Datenschutz from "./containers/Datenschutz"
import Footer from "./components/Footer"
import CookieConsent from "./components/CookieConsent";

import './App.css';

export const routes = (
  <Routes>
  <Route
  path = "/" 
  element = {<Home position={'home'}/>}
  />
  <Route
  path = "/Produkt"
  element = {<Home position={'mathematisch'}/> }
  />
  <Route
  path = "/Tech"
  element = {<Tech/> }
  />
  <Route
  path = "/Team"
  element = {<Home position={'team'}/> }
  />
  <Route
  path = "/Kontakt"
  element = {<Home position={'kontaktformular'}/> }
  />
  <Route
  path = "/Impressum"
  element = {<Impressum/> }
  />
   <Route
  path = "/Datenschutz"
  element = {<Datenschutz/> }
  />
 
</Routes>
);

function App() {
  {/* define cookie consent variable */ }
  const [cookies] = useCookies(["cookieConsent"])
  
  
  return (
    <div id="app" >
      {/* set device initial viewport settings*/}
      <meta name = "viewport" content = "width=device-width" initial-scale={1} minimum-scale={1} maximum-scale={1} />
      
      <Router>
        <Navbar/>
        {/* if cookieconsent has not been set yet then view cookiebanner*/}
        {!cookies.cookieConsent && <CookieConsent/> }

        {/*set routes */}
        {routes}  
        {/*view footer */}
        <Footer/>
        
      </Router>
    </div>
  );
}

export default App;
